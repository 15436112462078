@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.home-projects {
  color: $primary-color-3;
  padding-bottom: 1.6rem;

  &__container {
    @include flex(row, center, center, wrap);
    max-width: 102rem;
    margin: 1.6rem;

    @include tablet {
      margin: 1.6rem auto;
      padding-left: 1.6rem;
    }
  }

  &__title {
    text-align: left;
    width: 100%;
  }

  &__card {
    @include flex(column, space-between, center, nowrap);
    max-width: 27rem;
    width: 100%;
    text-align: center;
    background-color: $primary-color-3;
    padding: 1.6rem;
    margin-top: 1.6rem;
    border-radius: 1rem;
    box-shadow: 0.1rem 0.5rem 0.5rem black;
    text-decoration: none;
    color: $primary-color-1;

    &:hover {
      color: black;
    }

    @include tablet {
      @include size(27rem, 53rem);
      justify-content: space-between;
      margin: 1.6rem;
    }
  }

  &__card-image {
    width: 100%;
    border-radius: 0.5rem;
    box-shadow: 0.1rem 0.1rem 1rem black;
    object-fit: cover;
    margin: 1.6rem 0;

    @include tablet {
      @include size(24rem, 27rem);
    }
  }

  &__card-title {
    margin-top: 0.75rem;

    @include tablet {
      height: 5rem;
    }
  }

  &__card-techs {
    @include tablet {
      height: 12rem;
    }
  }

  &__link-container {
    @include flex(row, center, center, nowrap);
    margin-top: 1.6rem;
    width: 100%;
  }
}
