@use "./variables" as *;

@mixin tablet {
  @media (min-width: $tablet-breakpoint) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop-breakpoint) {
    @content;
  }
}

@mixin flex($direction, $justify, $items, $wrap) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $items;
  flex-wrap: $wrap;
}

@mixin size($width, $height) {
  width: $width;
  height: $height;
}

@mixin font-styles($font-size, $line-height, $font-weight) {
  font-size: $font-size;
  line-height: $line-height;
  font-weight: $font-weight;
}
