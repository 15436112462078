$tablet-breakpoint: 576px;
$desktop-breakpoint: 1280px;

$primary-color-1: #282c34;
$primary-color-2: #749ff7;
$primary-color-3: #abb2bf;

$secondary-color-1: #374c75;
$secondary-color-2: #95a4c2;

$base-color-1: black;
$base-color-2: white;
