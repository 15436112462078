@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.home-about {
  color: $primary-color-3;
  margin: 1.6rem;
  max-width: 102rem;

  @include tablet {
    margin: 2rem auto 0 auto;
    padding: 1.6rem;
  }

  &__container {
    @include tablet {
      @include flex(row, space-between, flex-start, nowrap);
      margin-top: 2rem;
    }
  }

  &__title {
    margin-bottom: 2rem;
  }

  &__image-container {
    width: 100%;
    max-width: 35rem;
    margin: 0 auto;

    @include tablet {
      margin: 0;
      width: 40%;
      max-width: none;
    }
  }

  &__image {
    width: 100%;
    border-radius: 1rem;
    box-shadow: 0.1rem 0.3rem 0.2rem black;

    @include tablet {
      max-width: 30rem;
      width: 100%;
    }
  }

  &__about-section-container {
    padding-bottom: 2rem;
    max-width: 35rem;
    margin: 0 auto;

    @include tablet {
      margin: 0;
      width: 60%;
      max-width: none;
    }
  }

  &__about-me-container {
    margin-bottom: 2.6rem;
    min-height: 39rem;

    @include tablet {
      min-height: 50rem;
    }
  }

  &__about-me {
    margin: 2rem auto;
    white-space: pre-wrap;
    line-height: 1.5;
    font-size: 1.1rem;

    @include tablet {
      margin: 0 2rem;
      font-size: 1.2rem;
    }
  }

  &__buttons-container {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }
}
